<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row class="ma-4">
      <v-col>
        <v-card class="elevation-6">
          <w-entity-list
            :columns="columns"
            :items="jobs"
            title="Run jobs"
            sort-by="started,desc"
            rows="10"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "../../components/external/modules/shared/utils/api";
import datehourformat from "../../components/external/modules/shared/utils/datehourformat";
import { VCard, VCol, VContainer, VRow } from "vuetify/lib";
import WEntityList from "../../components/external/modules/shared/views/WEntityList";

export default {
  components: { VCard, VCol, VContainer, VRow, WEntityList },

  data: () => ({
    jobs: [],
    columns: [
      { value: "uid", text: "ID" },
      { value: "schedule", text: "schedule" },
      { value: "started", text: "started" },
      { value: "duration", text: "duration ms" },
      { value: "statusString", text: "status" },
      { value: "comment", text: "comment" },
    ],
  }),

  created() {
    this.search();
  },

  methods: {
    search() {
      this.jobs = []; // cleaning the jobs

      // performing the search
      api
        .get("job/list/-1")
        .then((res) => {
          if (res.data.status === 200 && res.data.entities !== null) {
            this.jobs = res.data.entities.map((job) => {
              // formatting the creation date - NB: this should probably be put into a reusable function
              job.started = datehourformat(job.started);
              return job;
            });
          } else {
            console.log(new Error("Failed listing the jobs"));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>
